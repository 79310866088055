import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMeasurements, updateCervicalSpine, updateLumbarSpine, updateReportGeneralInfo } from '../redux/ReportBuilder';

function Template() {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.reportBuilder.formData);

  const measurements = [
    { id: 'liver', label: 'Liver' },
    { id: 'spleen', label: 'Spleen' },
    { id: 'right_kidney', label: 'Right kidney' },
    { id: 'left_kidney', label: 'Left kidney' },
    { id: 'endometrial_thickness', label: 'Endometrial thickness/ Prostate' },
  ];

  const cervicalSpineLevels = ['C3-C4', 'C4-C5', 'C5-C6', 'C6-C7', 'C7-T1'];
  const lumbarLevels = ['L1-L2', 'L2-L3', 'L3-L4', 'L4-L5', 'L5-S1'];

  const handleInputChange = (section, field, value) => {
    switch (section) {
      case 'measurements':
        dispatch(updateMeasurements({ [field]: value }));
        break;
      case 'cervical_spine':
        dispatch(updateCervicalSpine({ [field]: value }));
        break;
      case 'lumbar_spine':
        dispatch(updateLumbarSpine({ [field]: value }));
        break;
      case 'additional_findings':
        dispatch(updateReportGeneralInfo({ additional_findings: value }));
        break;
      default:
        console.error('Unknown section:', section);
    }
  };



  const renderInputRow = (section, label) => (
    <tr key={label}>
      <td className="border border-gray-300 px-4 py-2">{label}</td>
      {Object.keys(formData.report_general_info[section]).map((level) => (
        <td key={level} className="border border-gray-300 px-4 py-2">
          <input
            className="w-full"
            value={formData.report_general_info[section][level]}
            onChange={(e) => handleInputChange(section, level, e.target.value)}
          />
        </td>
      ))}
    </tr>
  );

  console.log("formdatavaluesfromtemplate",formData)

  return (
    <div className="p-3 pt-4">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Measurements</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {measurements.map(measurement => (
          <div key={measurement.id}>
            <label htmlFor={measurement.id} className="block text-sm font-medium text-gray-700">
              {measurement.label} :
            </label>
            <input
              id={measurement.id}
              className="mt-1 border-2 rounded-lg p-2"
              value={formData.report_general_info.measurements[measurement.id]}
              onChange={(e) => handleInputChange('measurements', measurement.id, e.target.value)}
            />
          </div>
        ))}
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-700">Cervical Spine :</h2>
      <div className="overflow-x-auto mb-6">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2 text-left">Level</th>
              {cervicalSpineLevels.map(level => (
                <th key={level} className="border border-gray-300 px-4 py-2 text-left">{level}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderInputRow('cervical_spine', 'AP (mm)')}
          </tbody>
        </table>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-700">Lumbar canal diameters :</h2>
      <div className="overflow-x-auto mb-6">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2 text-left">Level</th>
              {lumbarLevels.map(level => (
                <th key={level} className="border border-gray-300 px-4 py-2 text-left">{level}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderInputRow('lumbar_spine', 'AP (mm)')}
          </tbody>
        </table>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-700">Additional findings</h2>
      <textarea
        className="w-full h-32 border-2 rounded-lg"
        value={formData.report_general_info.additional_findings}
        onChange={(e) => handleInputChange('additional_findings', '', e.target.value)}
      />
    </div>
  );
}

export default Template;