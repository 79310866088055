import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const setFormData = createAsyncThunk(
  'reportBuilder/setFormData',
  async (formData, { rejectWithValue }) => {
    try {
      return formData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const reportBuilderSlice = createSlice({
  name: 'reportBuilder',
  initialState: {
    loading: false,
    error: null,
    formData: JSON.parse(sessionStorage.getItem('formData')) || {
      patient_id: null,
      mod_study: '',
      patient_name: '',
      patient_age: '',
      patient_sex: '',
      study_iuid: '',
      user: '',
      status: '',
      html_content: '',  
      selectedPathology:[],
      report_by_pathology: {
        report_contents: []
      },
      report_general_info: {
        measurements: {
          liver: '',
          spleen: '',
          right_kidney: '',
          left_kidney: '',
          endometrial_thickness: ''
        },
        cervical_spine: {
          'C3-C4': '',
          'C4-C5': '',
          'C5-C6': '',
          'C6-C7': '',
          'C7-T1': ''
        },
        lumbar_spine: {
          'L1-L2': '',
          'L2-L3': '',
          'L3-L4': '',
          'L4-L5': '',
          'L5-S1': ''
        },
        additional_findings: '',
        impression: [],
        clinical_images: []
      }
    }
  },
  reducers: {
    resetFormData: (state) => {
      state.formData = {
        patient_id: null,
        mod_study: '',
        patient_name: '',
        patient_age: '',
        patient_sex: '',
        study_iuid: '',
        user: '',
        status: '',
        html_content: '',  
        selectedPathology:[],
        report_by_pathology: {
          report_contents: []
        },
        report_general_info: {
          measurements: {
            liver: '',
            spleen: '',
            right_kidney: '',
            left_kidney: '',
            endometrial_thickness: ''
          },
          cervical_spine: {
            'C3-C4': '',
            'C4-C5': '',
            'C5-C6': '',
            'C6-C7': '',
            'C7-T1': ''
          },
          lumbar_spine: {
            'L1-L2': '',
            'L2-L3': '',
            'L3-L4': '',
            'L4-L5': '',
            'L5-S1': ''
          },
          additional_findings: '',
          impression: [],
          clinical_images: []
        }
      };
    },
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    updatePatientInfo: (state, action) => {
      Object.assign(state.formData, action.payload);
    },
    setSelectedPatientData: (state, action) => {
      const { patient_id, patient_name, patient_age, patient_sex, mod_study, status, study_iuid,user } = action.payload;
      state.formData.patient_id = patient_id;
      state.formData.patient_name = patient_name;
      state.formData.patient_age = patient_age;
      state.formData.patient_sex = patient_sex;
      state.formData.mod_study = mod_study;
      state.formData.status = status;
      state.formData.study_iuid = study_iuid;
      state.formData.user=user;
    },
    updateReportContents: (state, action) => {
      state.formData.report_by_pathology.report_contents = action.payload;
    },
    addReportContent: (state, action) => {
      state.formData.report_by_pathology.report_contents.push(action.payload);
    },
    updateReportGeneralInfo: (state, action) => {
      state.formData.report_general_info = { ...state.formData.report_general_info, ...action.payload };
    },
    updateMeasurements: (state, action) => {
      state.formData.report_general_info.measurements = { ...state.formData.report_general_info.measurements, ...action.payload };
    },
    updateCervicalSpine: (state, action) => {
      state.formData.report_general_info.cervical_spine = { ...state.formData.report_general_info.cervical_spine, ...action.payload };
    },
    updateLumbarSpine: (state, action) => {
      state.formData.report_general_info.lumbar_spine = { ...state.formData.report_general_info.lumbar_spine, ...action.payload };
    },
    addImpression: (state, action) => {
      state.formData.report_general_info.impression.push(action.payload);
    },
    removeImpression: (state, action) => {
      state.formData.report_general_info.impression = state.formData.report_general_info.impression.filter((_, index) => index !== action.payload);
    },
    addClinicalImage: (state, action) => {
      const { pathologyName, imageUrl } = action.payload;
      const reportContent = state.formData.report_by_pathology.report_contents.find(
        content => content.pathology === pathologyName
      );
      if (reportContent) {
        if (!reportContent.image_url) {
          reportContent.image_url = [];
        }
        reportContent.image_url.push(imageUrl);
      }
    },
    removeClinicalImage: (state, action) => {
      const { pathologyName, imageIndex } = action.payload;
      const reportContent = state.formData.report_by_pathology.report_contents.find(
        content => content.pathology === pathologyName
      );
      if (reportContent && reportContent.image_url) {
        reportContent.image_url.splice(imageIndex, 1);
      }
    },
    storeClinicalImages: (state) => {
      state.formData.report_general_info.clinical_images = [];
    },
    updateHtmlContent: (state, action) => {
      state.formData.html_content = action.payload;
    },
    updateCurrentStatus: (state, action) => {
      state.formData.status = action.payload;
    },
    addSelectedPathology:(state,action)=>{
      if (Array.isArray(action.payload)) {
        state.formData.selectedPathology = action.payload;
      } else {
        console.error('Payload for addSelectedPathology must be an array');
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setFormData.pending, (state) => {
        state.loading = true;
      })
      .addCase(setFormData.fulfilled, (state, action) => {
        state.loading = false;
        state.formData = action.payload;
      })
      .addCase(setFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  updateFormData,
  resetFormData,
  updatePatientInfo,
  updateReportContents,
  addReportContent,
  updateReportGeneralInfo,
  updateMeasurements,
  updateCervicalSpine,
  updateLumbarSpine,
  addImpression,
  removeImpression,
  addClinicalImage,
  removeClinicalImage,
  storeClinicalImages,
  setSelectedPatientData,
  updateHtmlContent,
  updateCurrentStatus,
  addSelectedPathology
} = reportBuilderSlice.actions;

export default reportBuilderSlice.reducer;
